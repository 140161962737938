import { createStore, combineReducers } from "redux";

import apiReducer from "./Reducers/api-reducers";

const rootReducer = combineReducers(
    {apiReducer}
);
const store = createStore(rootReducer);

export default store;

import "./about.css";
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer/footer";
import Navbar from "../../Components/Navbar/navbar";
import debu from "../../Assets/Profile-Pics/Debo.jpg";
import asif from "../../Assets/Profile-Pics/asif.jpg";
import sajda from "../../Assets/Profile-Pics/sajda.jpg";
import sayantan from "../../Assets/Profile-Pics/sayantan.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faLinkedin, faGithub, faWhatsapp } from "@fortawesome/free-brands-svg-icons";



const about = () => {
  return (
    <>
      <Navbar />
      <div className="about-container">
        <section>
          <div class="about-row">
            <h1 class="about-heading">Our Team</h1>
          </div>
          <div class="about-row">
            <div class="about-column">
              <div class="about-card">
                <div class="img-container">
                  <img
                    src={sajda}
                    alt="Network Error"
                    height="110px"
                    width="150px"
                  />
                </div>
                <h3>SAJDA PARVEEN</h3>
                <p>
                  Team Leader <br />
                  WEB DEVELOPER
                </p>
                <div class="icons">
                  <Link >
                    <FontAwesomeIcon icon={faTwitter} />
                  </Link>
                  <Link >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </Link>
                  <Link>
                     <FontAwesomeIcon icon={faGithub} />
                  </Link>
                  <Link >
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </Link>
                </div>
              </div>
            </div>

            <div class="about-column">
              <div class="about-card">
                <div class="img-container">
                  <img
                    src={asif}
                    alt="Network Error"
                    height="110px"
                    width="150px"
                  />
                </div>
                <h3>ASIF KARIM</h3>
                <p>ANROID DEVELOPER</p>
                <div class="icons">
                <Link >
                    <FontAwesomeIcon icon={faTwitter} />
                  </Link>
                  <Link >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </Link>
                  <Link>
                     <FontAwesomeIcon icon={faGithub} />
                  </Link>
                  <Link >
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </Link>
                </div>
              </div>
            </div>

            <div class="about-column">
              <div class="about-card">
                <div class="img-container">
                  <img
                    src={debu}
                    alt="Network Error"
                    height="110px"
                    width="150px"
                  />
                </div>
                <h3>DEBARIYA DAS</h3>
                <p>
                  DATA COLLECTOR <br />
                  DATABASE MANAGER
                </p>
                <div class="icons">
                <Link >
                    <FontAwesomeIcon icon={faTwitter} />
                  </Link>
                  <Link >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </Link>
                  <Link>
                     <FontAwesomeIcon icon={faGithub} />
                  </Link>
                  <Link >
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </Link>
                </div>
              </div>
            </div>

            <div class="about-column">
              <div class="about-card">
                <div class="img-container">
                  <img
                    src={sayantan}
                    alt="Network Error"
                    height="110px"
                    width="150px"
                  />
                </div>
                <h3>SAYANTAN SAHA</h3>
                <p>BACKEND-DEVELOPER</p>
                <div class="icons">
                <Link >
                    <FontAwesomeIcon icon={faTwitter} />
                  </Link>
                  <Link >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </Link>
                  <Link>
                     <FontAwesomeIcon icon={faGithub} />
                  </Link>
                  <Link >
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default about;

import React from "react";
import Navbar from "../../Components/Navbar/navbar";
import { Button } from "@mui/material";
import "./home.css";
import svg from "../../Assets/undraw_explore.svg";
import svg2 from "../../Assets/undraw_code_snippets.svg";
import Footer from "../../Components/Footer/footer";
import { useNavigate } from "react-router-dom";

const Home = () => {

  const navigate = useNavigate();

  const navigateToUpload = () => {
    navigate("/upload");
  }

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="sub-container">
          <h1 className="heading-main">Let Us</h1>
          <h1 className="heading-sub">
            Take Care of Your <span className="span">Plants</span>
          </h1>
          <p className="tag-line">
            Root out plant problem with ease, Say goodbye to crop losses and
            hello to healty yields - Your all-in-one plant health solution.
          </p>
          <Button onClick={navigateToUpload} className="explore-button">Explore</Button>
        </div>
        <div class="sub-container">
          <img src={svg} alt="intro-pic" />
        </div>
      </div>

      <h2 className="content-heading">
        Solution at your fingertip in less than 5 seconds
      </h2>

      <div className="content">
        <div className="content-sub">
          <p className="tag-line">
            Thanks to agrocare's clever AI, you can automatically identify and
            cure your vegetation! 
          </p>
          <p className="tag-line">
          If your plant is showing signs of distress,
            our app can help you identify the problem and provide solutions
            in just a blink.
          </p>
        </div>
        <div className="sub-content">
          <img src={svg2} alt="intro-pic" />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;

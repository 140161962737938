import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
  return (
    <>
      <footer>
        <div className="waves">
            <div className="wave" id="wave-1"></div>
            <div className="wave" id="wave-2"></div>
            <div className="wave" id="wave-3"></div>
            <div className="wave" id="wave-4"></div>
        </div>
        <ul className="social-icons">
          <li>
            <Link className="link" to="/">
              <FontAwesomeIcon icon={ faFacebook }></FontAwesomeIcon>
            </Link>
          </li>
          <li>
            <Link className="link" to="/">
            <FontAwesomeIcon icon={ faTwitter }></FontAwesomeIcon>
            </Link>
          </li>
          <li>
            <Link className="link" to="/">
            <FontAwesomeIcon icon={ faLinkedin }></FontAwesomeIcon>
            </Link>
          </li>
          <li>
            <Link className="link" to="/">
            <FontAwesomeIcon icon={ faInstagram }></FontAwesomeIcon>
            </Link>
          </li>
        </ul>
        <ul className="menu">
            <li>
                <Link className="link" to="/upload">
                Disease Detection
                </Link>
            </li>
            <li>
                <Link className="link" to="/api-key">
                    Developer Options
                </Link>
            </li>
            <li>
                <Link className="link" to="/about">
                    About Us
                </Link>
            </li>
            <li>
                <Link className="link" to="/services">
                    Services
                </Link>
            </li>
        </ul>
        <p>© 2023 Agrocare | All Right Reserved</p>
      </footer>
    </>
  );
};

export default Footer;
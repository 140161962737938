import { ADD_API_KEY } from "../Actions/action-types";

const initialApiKey = "http://127.0.0.1:8000/predict";

// eslint-disable-next-line
export default (state=initialApiKey, action) => {
    
    switch(action.type){
        case ADD_API_KEY:
            return action.payload;
        default:
            return state;
    }
}
import React from "react";
import "./enviroment.css";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import Navbar from "../Components/Navbar/navbar";
import { Card, Box, TextField } from "@mui/material";
import { addApiKey } from "../Redux/Actions/api";
import { connect } from "react-redux";
import Footer from "../Components/Footer/footer";

const Enviroment = ({ addApiKey }) => {
  const [apiKey, setApiKey] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const handleApiKey = () => {

    let api = "http://127.0.0.1:8000/predict";

    if (apiKey.endsWith('/')) {
        api = `${apiKey}predict`;
      } else {
        api = `${apiKey}/predict`;
      }


    // Add url to redux store
    addApiKey(api);

    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false); // Hide the alert when closed
  };

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Adjust the timeout duration (in milliseconds) as needed
      return () => clearTimeout(timeout);
    }
  }, [showAlert]);

  return (
    <>
      <Navbar />
      {showAlert && (
        <Alert
          sx={{
            position: "absolute",
            right: 20,
            marginTop: "1rem",
          }}
          severity="success"
          onClose={handleCloseAlert}
        >
          New API registered successfully!
        </Alert>
      )}
      <div className="enviroment-container">
        <Card
          sx={{
            maxWidth: "800px",
            padding: "20px",
            marginTop: "2rem",
          }}
          className="card-content"
        >
          <Box sx={{}}>
            <label> </label>
            <TextField
              onChange={(e) => setApiKey(e.target.value)}
              fullWidth
              label="Please Enter Your API Key :"
            />
          </Box>
          <Button
            sx={{
              marginTop: "1.3rem",
            }}
            variant="contained"
            onClick={handleApiKey}
          >
            Send
          </Button>
        </Card>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  addApiKey: (api) => dispatch(addApiKey(api)),
});

export default connect(mapStateToProps, mapDispatchToProps) (Enviroment);

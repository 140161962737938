import "./dragnDrop.css";
import axios from "axios";
import Lottie from "lottie-react";
import { React, useState } from "react";
import upload from "../../Assets/upload.json";
import Details from "../Details/details";
import { connect } from "react-redux";



const DragAndDrop = ({api}) => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState("");
  const [error, setError] = useState("No Error");
  const [isLoading, setIsLoading] = useState(true);


  async function handleFileUpload(e) {
    setIsLoading(true);
    const file = e.target.files[0];
    setFile(file);
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", file);

    try {

      const response = await axios.post(api, formData, {
        headers: {
          "Content-Type": "image/*",
        },
      });

      setData(response.data.data);
      setError("No Error");

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);

    } catch (error) {

      let details = {
        confidence: '',
        predicted_class: '',
        symptoms: 'Please Try Again!',
        cure: 'Please Try Again!'
      }

      setData(details);
      setIsLoading(false);
      setError(error.message);

    }
  }


  return (
    <>
      <div className="drag-drop-container">
        <input
          id="file"
          onChange={handleFileUpload}
          type="file"
          accept="image/*"
        />
        <label className="upload-label" htmlFor="file">
          Upload
          <Lottie className="lottie-upload" animationData={upload} />
        </label>
      </div>
      {file && (
        <Details active={isLoading} file={file} data={data} error={error} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {api: state.apiReducer};
}

const mapDispatchToProps = (dispatch) => {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps) (DragAndDrop);

import { React } from "react";
import "./upload.css";
import Navbar from "../../Components/Navbar/navbar";
import DragAndDrop from "../../Components/DragnDrop/dragnDrop";
import Footer from "../../Components/Footer/footer";

const Upload = () => {
  return (
    <>
      <div className="container-upload">
        <Navbar />
        <h1 className="upload-header">
          Upload an image to <span className="upload-span">Analyze</span>
        </h1>
        <DragAndDrop />
      </div>
      <Footer />
    </>
  );
};

export default Upload;

import Home from "./Pages/Home/home";
import { Provider } from "react-redux";
import About from "./Pages/About/about";
import Error from "./Pages/Error/error";
import Upload from "./Pages/Upload/upload";
import Services from "./Pages/Services/services";
import Enviroment from "./Enviroment/enviroment";
import { Routes, Route } from "react-router-dom";
import store from "./Redux/store";
import { useState, useEffect } from "react";
import Preloader from "./Components/Preloader/preloader";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time or API fetch
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  }, []);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <Provider store={store}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Error />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/services" element={<Services />} />
            <Route path="/api-key" element={<Enviroment />} />
          </Routes>
        </Provider>
      )}
    </>
  );
}

export default App;

import React from "react";
import "./error.css"

const Error = () => {
  return (
    <>
      
    </>
  );
};

export default Error;

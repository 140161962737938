import "./navbar.css";
import { NavLink } from "react-router-dom";
import { React, Component } from "react";
import logo from "../../Assets/logo-svg.png";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

class Navbar extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    return (
      <>
        <nav className="hero">
          <div className="logo">
            <img className="logo-svg" src={logo} alt="Agrocare_Official_Logo" />
            <h2 className="logo-text">Agrocare</h2>
          </div>
          <ul className={this.state.clicked ? "hero-list active" : "hero-list"}>
            <li className="hero-items">
              <NavLink className="hero-links" to="/">
                Home
              </NavLink>
            </li>
            <li className="hero-items">
              <NavLink className="hero-links" to="/services">

                Services
              </NavLink>
            </li>
            <li className="hero-items">
              <NavLink className="hero-links" to="/about">
                About Us
              </NavLink>
            </li>
          </ul>
          <div className="icon-container">
            <IconButton className="icon-holder" onClick={this.handleClick}>
              <FontAwesomeIcon
                className="icon"
                icon={this.state.clicked ? faTimes : faBars}
              />
            </IconButton>
          </div>
        </nav>
      </>
    );
  }
}

export default Navbar;

import React from "react";
import "./services.css";
import { NavLink } from "react-router-dom";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";

const Services = () => {
  return (
    <>
      <Navbar />
      <div className="service-section">
        <h1 className="heading-service">Services</h1>

        <section className="web-view-section">
          <h2 className="subheading-service">Web View Version</h2>
          <p className="para-service">
            Click the button below to view the web version of our service:
          </p>
          <NavLink className="hero-links" to="/home">
            <button class="btn">Go to Web View</button>
          </NavLink>
        </section>

        <section className="mobile-view-section">
          <h2 className="subheading-service">Mobile View Download</h2>
          <p className="para-service">
            Click the button below to download our mobile app:
          </p>
          <NavLink className="hero-links" to="/home">
            <button class="btn">Download Mobile App</button>
          </NavLink>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Services;

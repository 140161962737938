import "./details.css";
import Lottie from "lottie-react";
import { Skeleton } from "antd";
import { Box, Grid } from "@mui/material";
import error from "../../Assets/error.json";
import { React, useState, useEffect, useRef } from "react";
import status from "../../Assets/submit-smile.json";
import neutral from "../../Assets/neutral-emoji.json";
import analyse from "../../Assets/analyse.json";
import loading from "../../Assets/lineLoading.json";

const Details = (prop) => {
  const confidence = (prop.data.confidence * 100).toFixed(2);
  const [degree, setDegree] = useState("");
  const scrollRef = useRef(null);
  const [value, setValue] = useState(false);

  useEffect(() => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  }, [value]);

  const handleInputChange = () => {
    setValue(true);
  };

  useEffect(() => {
    const handleDegree = () => {
      if (80 < confidence && confidence <= 100) {
        setDegree("High");
      } else if (50 < confidence && confidence < 79) {
        setDegree("Low");
      } else {
        setDegree("Not Sure !");
      }
    };
    handleDegree();
  }, [confidence]);

  return (
    <>
      {/* Main Grid */}

      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        spacing={4}
        marginTop={4}
        onChange={handleInputChange}
      >
        <Grid item xs={11} sm={11} md={8} lg={6} xl={6} ref={scrollRef}>
          <Box
            className="glass-morph"
            sx={{
              minHeight: 300,
              transition: "all 0.3s ease-in-out",
              "@media (max-width: 600px)": {
                height: 460,
              },
            }}
          >
            {/* Sub Grid */}

            <Grid
              container
              justifyContent={"space-around"}
              alignItems={"center"}
              sx={{
                margin: "1.4rem 0 0 0",
              }}
            >
              <Grid item xs={10} sm={7} md={5} lg={5} xl={4}>
                <Box
                  sx={{
                    width: 290,
                    height: 250,
                  }}
                >
                  {prop.active ? (
                    <div className="image-skeleton-container">
                      <Skeleton.Image
                        className="image-skeleton"
                        style={{ height: 250, width: 290 }}
                        active
                      />
                    </div>
                  ) : (
                    <img
                      className="image-placeholder"
                      src={URL.createObjectURL(prop.file)}
                      alt="Uploded Data"
                    />
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={10}
                sm={5}
                md={4}
                lg={4}
                xl={6}
                sx={{
                  "@media (max-width: 756px)": {
                    marginTop: "2rem",
                    justifyContent: "center",
                  },
                }}
              >
                {prop.error === "No Error" ? (
                  <>
                    {prop.active ? (
                      <Skeleton
                        active
                        title={{ width: 290 }}
                        paragraph={{
                          rows: 2,
                          width: [290, 100],
                        }}
                      ></Skeleton>
                    ) : (
                      <>
                        <h2 className="heading">{prop.data.predicted_class}</h2>
                        <Grid
                          container
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          
                          sx={{
                            marginLeft: 2,
                            "@media (min-width: 600px)": {
                              marginTop: 1,
                              flexDirection: "column",
                              alignItems: "start",
                            },
                          }}
                        >
                          <Grid item xs={4} sm={5} md={4} lg={4} xl={6}>
                            <p className="description">
                              Confidence: <span>{degree}</span>
                            </p>
                          </Grid>
                          <Grid item xs={4} sm={5} md={4} lg={4} xl={6}>
                            {degree === "High" ? (
                              <Lottie
                                className="lottie-1"
                                animationData={status}
                                loop={false}
                              />
                            ) : (
                              <Lottie
                                className="lottie-1"
                                animationData={neutral}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        "@media (max-width: 756px)": {
                          flexDirection: "row",
                          justifyContent: "space-around",
                        },
                      }}
                    >
                      <p className="description">{prop.error}</p>
                      <br />
                      <Lottie animationData={error} />
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={11} sm={11} md={8} lg={5} xl={4}>
          <Box
            className="glass-morph symptoms-box"
            sx={{
              padding: "2rem",
              minHeight: 300,
              maxHeight: 300,
              fontSize: "1.2rem",
              overflow: "auto",
            }}
          >
            {prop.active ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  "@media (max-width: 756px)": {
                    flexDirection: "row",
                    justifyContent: "space-around",
                  },
                }}
              >
                <Lottie animationData={analyse} />
              </Box>
            ) : (
              <>
                <h1 className="heading-symptoms">Symptoms</h1>
                <br />
                <p className="description-symptoms">{prop.data.symptoms}</p>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={11} sm={11} md={8} lg={11} xl={10}>
          <Box
            className="glass-morph cure-box"
            sx={{
              padding: "2rem",
              minHeight: 300,
              maxHeight: 300,
              fontSize: "1.2rem",
              overflow: "auto",
            }}
          >
            {prop.active ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 180,
                  "@media (max-width: 756px)": {
                    flexDirection: "row",
                    justifyContent: "space-around",
                  },
                }}
              >
                <Lottie animationData={loading} />
              </Box>
            ) : (
              <>
                <h1 className="heading-cure">Cure</h1>
                <br />
                <p className="description-cure">{prop.data.cure}</p>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Details;

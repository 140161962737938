import React from "react";
import "./preloader.css";
import Lottie from "lottie-react";
import preloader from "../../Assets/preloader.json";

const Preloader = () => {
  return (
    <>
      <div className="preloader">
        <Lottie className="preloader-lottie" animationData={preloader} />
      </div>
      
    </>
  );
};

export default Preloader;
